import React, {useEffect} from 'react';
import './App.css';
import {Box, CssBaseline, CssVarsProvider} from "@mui/joy";
import {FeedbackProvider} from "./utils/FeedbackContext";
import {Router} from "./utils/Router";
import 'moment/locale/it';

function App() {
    useEffect(() => {
        console.info('\x1b[34m%s\x1b[0m', // \x1b[38;5;33m
            "\n\n" +
            "   ___   ___ _____ ___  ___ _   _ ___ \n" +
            "  / _ \\ / __|_   _/ _ \\| _ \\ | | / __|\n" +
            " | (_) | (__  | || (_) |  _/ |_| \\__ \\\n" +
            "  \\___/ \\___| |_| \\___/|_|  \\___/|___/\n\n" +
            "         Developed with <3 by\n" +
            "        DANIELE DI GREGORIO .it\n" +
            "          www.octopusdesk.it\n")
    }, []);


    return (
        <div>
            <FeedbackProvider>
                <CssVarsProvider defaultMode="light">
                    <CssBaseline />
                    <Box sx={{
                        minHeight: '100%',
                        maxWidth: '100vw',
                        backgroundColor: "var(--joy-palette-background-body)",
                    }}
                    >
                        {/*<ModeToggle/>*/}
                        <Router />
                    </Box>
                </CssVarsProvider>
            </FeedbackProvider>
        </div>
    );
}

export default App;
