import {createContext, ReactNode, useEffect, useState} from "react";
import {IconButton, Snackbar} from "@mui/joy";
import {Close} from "@mui/icons-material";
import {CompanyDetailsModel} from "./Model";
import {APIurl} from "./Utils";


export const FeedbackContext = createContext<any>(null);

interface FeedbackProviderProps {
    children?: ReactNode
}

export function FeedbackProvider(props: FeedbackProviderProps) {
    const [bigloading, setBigLoading] = useState(false);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState<any>("");
    const [errorVisible, setErrorVisible] = useState(false);
    const [message, setMessage] = useState("");
    const [company, setCompany] = useState<CompanyDetailsModel | undefined>()
    const [logo, setLogo] = useState<string | null>(null)

    useEffect(() => {
        if(error !== undefined && error !== "" && error !== null) {
            setErrorVisible(true)
        }
    }, [error])

    useEffect(() => {
        fetchUserCompany().then(() => {})
        fetchLogo().then(() => {})
    }, []);

    async function fetchUserCompany(): Promise<void> {
        try {

            const response = await fetch(`${APIurl}/getBookingCompany`, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                }
            })
            if (!response.ok) {
                const error = await response.clone().json();
                setError(error);
            } else {
                const data = await response.json()
                setCompany(data)
            }
        } catch (error) {
            throw error;
        }
    }
    async function fetchLogo(): Promise<void> {
        try {

            const response = await fetch(`${APIurl}/uploads/logo`, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                }
            })
            if(response.ok) {
                const blob = await response.blob();
                const url = URL.createObjectURL(blob);
                setLogo(url);
            }
        } catch (error) {
            throw error;
        }
    }


    return (
        <FeedbackContext.Provider value={{loading, bigloading, error, message, setLoading, setBigLoading, setError, setMessage, company, logo}}>
            {props.children}
            <Snackbar
                autoHideDuration={5000}
                open={errorVisible}
                variant="outlined"
                color="danger"
                size="md"
                onClose={() => {
                    setErrorVisible(false)
                }}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                endDecorator={
                    <IconButton
                        onClick={() => {
                            setErrorVisible(false)
                        }}
                        size="sm"
                    >
                        <Close />
                    </IconButton>
                }
            >
                {error.message ?? error.toString()}
            </Snackbar>
            <Snackbar
                autoHideDuration={5000}
                open={message !== ""}
                variant="outlined"
                color="primary"
                size="md"
                onClose={() => {
                    setMessage("");
                }}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                endDecorator={
                    <IconButton
                        onClick={() => setMessage("")}
                        size="sm"
                    >
                        <Close />
                    </IconButton>
                }
            >
                {message}
            </Snackbar>
        </FeedbackContext.Provider>
    )
}