import {PageStandard} from "../utils/PageStandard";
import {ReactNode, useContext, useEffect, useState} from "react";
import {
    AspectRatio, Button,
    Card, CardContent, CardOverflow, Checkbox, Chip,
    CircularProgress, DialogContent, DialogTitle, Divider, FormControl, FormHelperText, FormLabel,
    Grid, Input, Modal, ModalDialog, Option, Select, Sheet,
    Stack,
    Step,
    stepClasses,
    StepIndicator,
    stepIndicatorClasses,
    Stepper, Switch,
    Typography
} from "@mui/joy";
import {CheckCircle, ConfirmationNumber, ContentCut, Info, Event, Archive, Inventory} from "@mui/icons-material";
import {FeedbackContext} from "../utils/FeedbackContext";
import {ServiceModel, SlotModel, UserModel} from "../utils/Model";
import {
    APIurl,
    areObjectsEqual, clearPhone,
    formatPriceEuro, genUUID, getFlagEmoji,
    isValidEmail, isValidPhoneNumber,
    minutesToHoursString,
    TelefonoInput
} from "../utils/Utils";
import moment from "moment";
import {Link} from "react-router-dom";


export function Prenota() {
    const {loading, bigloading, error, message, setLoading, setBigLoading, setError, setMessage, company, logo} = useContext(FeedbackContext)

    enum TABS {
        SERVICE,
        CALENDAR,
        CUSTOMER,
        OVERVIEW,
        RESPONSE,
        PRENOTAZIONI_NON_ATTIVE,
    }
    const [tab, setTab] = useState<TABS>(TABS.SERVICE)

    const [services, setServices] = useState<ServiceModel[]>([])
    const [service, setService] = useState<ServiceModel | null>(null)

    const [slots, setSlots] = useState<{ [day: string]: [SlotModel] }>({})
    const [slot, setSlot] = useState<SlotModel | null>(null)

    const [employees, setEmployees] = useState<{id:string, name: string, surname: string}[]>([])
    const [employee, setEmployee] = useState<string | null>(null)

    const [response, setResponse] = useState<string | null>(null)

    const initialCustomer = {
        name: "",
        surname: "",
        email: "",
        phoneNumberPrefisso: "39",
        phoneNumberPhone: "",
        terms: false,
        marketing: true
    }
    const [customer, setCustomer] = useState(initialCustomer)

    useEffect(() => {
        if(company===undefined) {
            setLoading(true)
        } else {
            setLoading(false)
            if(!company?.gestionaleSettings.prenotazioniAttive) {
                setTab(TABS.PRENOTAZIONI_NON_ATTIVE)
            } else {
                setTab(TABS.SERVICE)
                fetchServices().then(() => {})
                fetchEmployees().then(() => {})
            }
        }
    }, [company]);
    async function fetchServices(): Promise<void> {
        try {

            const response = await fetch(`${APIurl}/getBookingServices`, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                }
            })
            if (!response.ok) {
                const error = await response.clone().json();
                setError(error);
            } else {
                const data = await response.json()
                setServices(data)
            }
        } catch (error) {
            throw error;
        }
    }
    async function fetchSlots(props: {serviceId: string, employeeId: string | null}): Promise<void> {
        try {
            const response = await fetch(`${APIurl}/getBookingSlots/${props.serviceId}/${props.employeeId}`, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                }
            })
            if (!response.ok) {
                const error = await response.clone().json();
                setError(error);
            } else {
                const data = await response.json()
                if(data.code === 200) setSlots(data.slots)
                else {
                    setError(data.message)
                }
            }
        } catch (error) {
            throw error;
        }
    }
    async function fetchEmployees(): Promise<void> {
        try {
            const response = await fetch(`${APIurl}/getBookingEmployees`, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                }
            })
            if (!response.ok) {
                const error = await response.clone().json();
                setError(error);
            } else {
                const data = await response.json()
                setEmployees(data)
            }
        } catch (error) {
            throw error;
        }
    }

    if(loading || company===undefined) return <CircularProgress />
    return (
        <PageStandard headerVisible >
            <Stack direction="column" spacing={2} justifyContent="center" alignItems="center" pb={4}>
                <StepperPrenota tab={tab} />
                <SwitchTabs />
            </Stack>
        </PageStandard>
    )

    function SwitchTabs() {
        switch (tab) {
            case TABS.SERVICE:
                return <ServiceTab />
            case TABS.CALENDAR:
                return <CalendarTab />
            case TABS.CUSTOMER:
                return <CustomerTab />
            case TABS.OVERVIEW:
                return <OverviewTab />
            case TABS.RESPONSE:
                return <ResponseTab />
            case TABS.PRENOTAZIONI_NON_ATTIVE:
                return <PrenotazioniNonAttiveTab />
        }
    }

    function ServiceTab() {
        if(services.length === 0) return <CircularProgress />
        return (
            <Stack direction="column" spacing={2} justifyContent="center" alignItems="center">
                <Typography level="h3">Seleziona un servizio</Typography>
                <Grid container spacing={2}>
                    {
                        services.map(s => {
                            return <CardService service={s} key={s.id} />
                        })
                    }
                </Grid>
            </Stack>
        )
    }
    function CardService(props: {service: ServiceModel}) {
        const service = props.service

        return (
            <Grid xs={12} md={4}>
                <Card variant="outlined" sx={{ height:"100%" }} className="hoverScale cursorPointer" onClick={() => {
                    setService(props.service)
                    fetchSlots({serviceId: props.service.id, employeeId: null}).then(() => {}) // todo change null
                    setTab(TABS.CALENDAR)
                }}>
                <CardOverflow>
                    <AspectRatio ratio="2" color="neutral" variant="soft">
                        {
                            service.imageUrl ?
                                <img
                                    src={service.imageUrl}
                                    loading="lazy"
                                    alt={service.title}
                                />
                                :
                                null
                        }
                    </AspectRatio>
                </CardOverflow>
                <CardContent>
                    <Typography level="title-md">
                        {service.title}
                    </Typography>
                    <Typography level="body-sm">
                        {service.description}
                    </Typography>
                </CardContent>
                <CardOverflow variant="soft">
                    <Divider inset="context" />
                    <CardContent orientation="horizontal" sx={{display:"flex", justifyContent:"space-between", alignItems:"center"}}>
                        <Stack direction="row" spacing={1}>
                            <Chip variant="soft"
                                  color="primary"
                            >
                                {minutesToHoursString(service.duration)}
                            </Chip>
                            {
                                service.price ?
                                    <Chip variant="soft"
                                          color="success"
                                    >
                                        {formatPriceEuro(service.price)}
                                    </Chip>
                                    :
                                    null
                            }
                        </Stack>
                    </CardContent>
                </CardOverflow>
            </Card>
            </Grid>
        )
    }

    function CalendarTab() {

        const [modal, setModal] = useState(false)

        if(areObjectsEqual(slots, {})) return <CircularProgress />
        return (
            <Stack direction="column" spacing={2} justifyContent="center" alignItems="center">
                <Typography level="h3">Seleziona un orario</Typography>
                <Stack direction="row" spacing={2}>
                    {
                        Object.keys(slots).map(day => <Stack direction="column" spacing={2} key={day}>
                            <Typography level="body-lg" fontWeight="bold" textAlign="center">
                                {moment(day, "YYYY-MM-DD").format("DD/MM")}
                            </Typography>
                            <Stack direction="column" spacing={1} sx={{maxHeight: {xs:"60vh", md:"50vh"}, pl: 1}}
                                   className="scrollbarOrari">
                                {
                                    slots[day].map(s =>
                                        <Button variant={slot?.start === s.start ? "solid" : "outlined"}
                                                size="sm"
                                                key={s.id}
                                                onClick={() => {
                                                    if(s.employeeId.length === 1) {
                                                        setSlot(s)
                                                        setEmployee(s.employeeId[0])
                                                        setTab(TABS.CUSTOMER)
                                                    }
                                                    else {
                                                        setSlot(s)
                                                        setModal(true)
                                                    }
                                                }}
                                        >
                                            {moment(s.start, "YYYY-MM-DD HH:mm:ss").format("HH:mm")}
                                        </Button>
                                    )
                                }
                            </Stack>
                        </Stack>)
                    }
                </Stack>
                <Modal
                    open={modal}
                    onClose={() => setModal(false)}
                    sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                >
                    <ModalDialog>
                        <DialogTitle>Scegli un addetto</DialogTitle>
                        <DialogContent>Sono disponibili più persone che possomno eseguire questo servizio</DialogContent>
                        <Stack spacing={2}>
                            <Card onClick={() => {
                                setEmployee(slot!.employeeId[Math.floor(Math.random() * slot!.employeeId.length)])
                                setModal(false)
                                setTab(TABS.CUSTOMER)
                            }} className="hoverScale">
                                <Typography>Indifferente</Typography>
                            </Card>
                            {
                                slot?.employeeId.map(emp => {
                                    const e = employees.filter(a => a.id===emp)[0]
                                    return <Card key={emp} onClick={() => {
                                        setEmployee(emp)
                                        setModal(false)
                                        setTab(TABS.CUSTOMER)
                                    }} className="hoverScale">
                                        <Typography>{e.name} {e.surname}</Typography>
                                    </Card>
                                })
                            }
                        </Stack>
                    </ModalDialog>
                </Modal>
            </Stack>
        )
    }

    function CustomerTab() {

        const [customer2, setCustomer2] = useState(initialCustomer)

        return (
            <Stack direction="column" spacing={2} justifyContent="center" alignItems="center">
                <Typography level="h3">Le tue informazioni</Typography>
                <Grid container spacing={2}>
                    <Grid xs={12} md={6}>
                        <FormControl size="sm">
                            <FormLabel>Nome *</FormLabel>
                            <Input type="text"
                                   placeholder="Mario"
                                   value={customer2.name}
                                   onChange={e => setCustomer2({...customer2, name: e.target.value})}
                                   error={customer2.name.trim() === ""}
                            />
                        </FormControl>
                    </Grid>
                    <Grid xs={12} md={6}>
                        <FormControl size="sm">
                            <FormLabel>Cognome *</FormLabel>
                            <Input type="text"
                                   placeholder="Rossi"
                                   value={customer2.surname}
                                   onChange={e => setCustomer2({...customer2, surname: e.target.value})}
                                   error={customer2.surname.trim() === ""}
                            />
                        </FormControl>
                    </Grid>
                    <Grid xs={12} md={6}>
                        <FormControl size="sm">
                            <FormLabel>Email *</FormLabel>
                            <Input type="email"
                                   placeholder="nome@mail.com"
                                   value={customer2.email}
                                   onChange={e => setCustomer2({...customer2, email: e.target.value})}
                                   error={!isValidEmail(customer2.email)}
                            />
                        </FormControl>
                    </Grid>
                    <Grid xs={12} md={6}>
                        <FormControl size="sm">
                            <FormLabel>Telefono *</FormLabel>
                            <TelefonoInput valuePrefisso={customer2.phoneNumberPrefisso}
                                           valuePhone={customer2.phoneNumberPhone}
                                           onChangePrefisso={e => setCustomer2({...customer2, phoneNumberPrefisso: e})}
                                           onChangePhone={e => setCustomer2({...customer2, phoneNumberPhone: e})}
                                           error={!isValidPhoneNumber(customer2.phoneNumberPrefisso + " " + customer2.phoneNumberPhone)}
                            />
                        </FormControl>
                    </Grid>
                    <Grid xs={12} md={6}>
                        <FormControl>
                            <Checkbox label="Acconsento al trattamento dei dati *"
                                      size="sm"
                                      checked={customer2.terms}
                                      onChange={() => setCustomer2({...customer2, terms: !customer2.terms})}
                                      color={!customer2.terms ? "danger" : "neutral"}
                            />
                        </FormControl>
                    </Grid>
                    <Grid xs={12} md={6}>
                        <FormControl>
                            <Checkbox label="Voglio ricevere comunicazioni di carattere promozionale"
                                      size="sm"
                                      checked={customer2.marketing}
                                      onChange={() => setCustomer2({...customer2, marketing: !customer.marketing})}
                            />
                        </FormControl>
                    </Grid>
                    <Grid xs={12} md={12} textAlign="end">
                        <Button disabled={
                            customer2.name.trim() === "" ||
                            customer2.surname.trim() === "" ||
                            !isValidEmail(customer2.email) ||
                            !isValidPhoneNumber(customer2.phoneNumberPrefisso + " " + customer2.phoneNumberPhone) ||
                            !customer2.terms
                        }
                                onClick={() => {
                                    setTab(TABS.OVERVIEW)
                                    setCustomer(customer2)
                                }}
                        >
                            Avanti
                        </Button>
                    </Grid>
                </Grid>
            </Stack>
        )
    }

    function OverviewTab() {

        const emp = employees.filter(a => a.id===employee)[0]
        return (
            <Stack direction="column" spacing={2} justifyContent="center" alignItems="center">
                <Typography level="h3">Riepilogo</Typography>
                <Stack direction="column" spacing={2}>
                    <Card
                        size="lg"
                        variant="soft"
                        sx={{
                            borderRadius: 'xl',
                            minWidth: { xs:"auto", md:"350px" },
                        }}
                    >
                        <CardContent>
                            <Stack spacing={2}>
                                <Stack spacing={1}>
                                    <Typography level="h3">La tua prenotazione</Typography>
                                    <Stack>
                                        <Typography level="body-md">
                                            Data: <b>{moment(slot!.start, "YYYY-MM-DD HH:mm:ss").format("DD/MM/YYYY")}</b>
                                        </Typography>
                                        <Typography level="body-md">
                                            Ora: <b>{moment(slot!.start, "YYYY-MM-DD HH:mm:ss").format("HH:mm")} - {moment(slot!.end, "YYYY-MM-DD HH:mm:ss").format("HH:mm")}</b>
                                        </Typography>
                                        <Typography level="body-md">
                                            Addetto: <b>{emp.name} {emp.surname}</b>
                                        </Typography>
                                    </Stack>
                                </Stack>
                                <Stack spacing={1}>
                                    <Typography level="h3">I tuoi dati</Typography>
                                    <Stack>
                                        <Typography level="body-md">Nome: <b>{customer.name}</b></Typography>
                                        <Typography level="body-md">Cognome: <b>{customer.surname}</b></Typography>
                                        <Typography level="body-md">Email: <b>{customer.email}</b></Typography>
                                        <Typography level="body-md">Telefono: <b>+{customer.phoneNumberPrefisso} {customer.phoneNumberPhone}</b></Typography>
                                    </Stack>
                                </Stack>
                            </Stack>
                        </CardContent>
                    </Card>
                    <Stack direction="row" spacing={2} sx={{
                        width: "100%",
                        display: 'flex',
                        justifyContent: "center",
                        alignItems: "center"
                    }}>
                        <Button
                            variant="outlined"
                            color="neutral"
                            fullWidth
                            onClick={() => {
                                setTab(2)
                            }}
                        >
                            Indietro
                        </Button>
                        <Button
                            variant="solid"
                            color="primary"
                            fullWidth
                            disabled={loading}
                            onClick={() => {
                                prenotaSlot()
                            }}
                        >
                            Prenota
                        </Button>
                    </Stack>
                </Stack>
            </Stack>
        )
    }
    async function prenotaSlot() {
        setLoading(true)
        try {
            const response = await fetch(`${APIurl}/addBookingBooking`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    date: moment(slot!.start, "YYYY-MM-DD HH:mm:ss").format("YYYY-MM-DD"),
                    start: moment(slot!.start, "YYYY-MM-DD HH:mm:ss").format("HH:mm"),
                    serviceId: service!.id,
                    employeeId: employee,
                    customer: {
                        name: customer.name,
                        surname: customer.surname,
                        email: customer.email,
                        phoneNumber: customer.phoneNumberPrefisso + " " + customer.phoneNumberPhone,
                        terms: customer.terms,
                        marketing: customer.marketing,
                    },
                    settings: {
                        reminderEmail: true,
                        reminderWhatsapp: true,
                    }
                })
            })
            if (!response.ok) {
                const error = await response.clone().json();
                setError(error);
            } else {
                const data = await response.json()
                setResponse(data.id)
                setTab(TABS.RESPONSE)
            }
        } catch (e: unknown) {
            setError(e)
        } finally {
            setLoading(false)
        }
    }

    function ResponseTab() {

        return (
            <Stack direction="column" spacing={2} justifyContent="center" alignItems="center">
                <Typography level="h3">Prenotazione confermata</Typography>
                <Stack direction="row" spacing={2}>
                    <Link to={"/b/" + response}>
                        <Button>
                            Gestisci la prenotazione
                        </Button>
                    </Link>
                </Stack>
            </Stack>
        )
    }

    function PrenotazioniNonAttiveTab() {

        return (
            <Stack direction="column" spacing={2} justifyContent="center" alignItems="center">
                <Typography level="h3">Ops</Typography>
                <Typography level="body-md" textAlign="center">Al momento non è possibile effettuare nuove prenotazioni.<br/>Riprova più tardi.</Typography>
                <Stack direction="row" spacing={2}>
                    <Link to={"/gestisci-prenotazione"}>
                        <Button>
                            Gestisci prenotazione
                        </Button>
                    </Link>
                </Stack>
            </Stack>
        )
    }

    function StepperPrenota(props: {tab: TABS}) {
        return (
            <Stepper
                size="lg"
                sx={{
                    width: '100%',
                    '--StepIndicator-size': {xs:"3rem", md:'3rem'},
                    '--Step-connectorInset': '0px',
                    [`& .${stepIndicatorClasses.root}`]: {
                        borderWidth: {xs:3, md:4},
                    },
                    [`& .${stepClasses.root}::after`]: {
                        height: {xs:3, md:4},
                    },
                    [`& .${stepClasses.completed}`]: {
                        [`& .${stepIndicatorClasses.root}`]: {
                            borderColor: 'primary.300',
                            color: 'primary.300',
                        },
                        '&::after': {
                            bgcolor: 'primary.300',
                        },
                    },
                    [`& .${stepClasses.active}`]: {
                        [`& .${stepIndicatorClasses.root}`]: {
                            borderColor: 'currentColor',
                        },
                    },
                    [`& .${stepClasses.disabled} *`]: {
                        color: 'neutral.outlinedDisabledColor',
                    },
                }}
            >
                <StepPrenota id={TABS.SERVICE} tab={props.tab} icon={<Inventory/>} label="Tipologia servizio"/>
                <StepPrenota id={TABS.CALENDAR} tab={props.tab} icon={<Event/>} label="Data appuntamento"/>
                <StepPrenota id={TABS.CUSTOMER} tab={props.tab} icon={<Info/>} label="I tuoi dati"/>
                <StepPrenota id={TABS.OVERVIEW} tab={props.tab} icon={<CheckCircle/>} label="Riepilogo"/>
                <StepPrenota id={TABS.RESPONSE} tab={props.tab} icon={<ConfirmationNumber/>} label="Prenotazione"/>
            </Stepper>
        )
    }
    function StepPrenota(props: {id: TABS, tab: TABS, icon: ReactNode, label: string}) {
        const active = tab === props.id
        const completed = tab > props.id
        const disabled = tab < props.id

        return (
            <Step
                orientation="vertical"
                active={active}
                completed={completed}
                disabled={disabled}
                indicator={
                    <StepIndicator variant={active ? "solid" : "outlined"} color={disabled ? "neutral" : "primary"}>
                        {props.icon}
                    </StepIndicator>
                }
            >
                {
                    active ?
                        <Typography
                            sx={{
                                textTransform: 'uppercase',
                                fontWeight: 'lg',
                                fontSize: '0.75rem',
                                letterSpacing: '0.5px',
                                display: {
                                    xs: "none",
                                    md: "block"
                                }
                            }}
                        >
                            {props.label}
                        </Typography>
                        :
                        null
                }
            </Step>
        )
    }
}