import {PageStandard} from "../utils/PageStandard";
import {ReactNode, useContext, useEffect, useState} from "react";
import {
    AspectRatio, Button,
    Card, CardContent, CardOverflow, Checkbox, Chip,
    CircularProgress, DialogContent, DialogTitle, Divider, FormControl, FormHelperText, FormLabel,
    Grid, Input, Modal, ModalDialog, Option, Select, Sheet,
    Stack,
    Step,
    stepClasses,
    StepIndicator,
    stepIndicatorClasses,
    Stepper, Switch,
    Typography
} from "@mui/joy";
import {CheckCircle, ConfirmationNumber, ContentCut, Info, Event, Archive, Inventory} from "@mui/icons-material";
import {FeedbackContext} from "../utils/FeedbackContext";
import {BookingModel, ServiceModel, SlotModel, UserModel} from "../utils/Model";
import {
    APIurl,
    areObjectsEqual, clearPhone,
    formatPriceEuro, genUUID, getFlagEmoji,
    isValidEmail, isValidPhoneNumber,
    minutesToHoursString,
    TelefonoInput
} from "../utils/Utils";
import moment from "moment";
import {Link, useParams} from "react-router-dom";


export function Prenotazione() {
    const {loading, bigloading, error, message, setLoading, setBigLoading, setError, setMessage, company, logo} = useContext(FeedbackContext)
    let { id } = useParams();

    enum TABS {
        BOOKING,
        DELETEDBOOKING,
        EDITBOOKING,
        OVERVIEWEDITBOOKING,
        CONFIRMEDITBOOKING,
        NOTFOUND,
    }
    const [tab, setTab] = useState<TABS>(TABS.BOOKING)

    const [booking, setBooking] = useState<{
        booking: BookingModel,
        customer: { name: string, surname: string, email: string, phoneNumber: string },
        employeeName: string,
        serviceName: string,
        canDelete: boolean,
        canEdit: boolean,
    } | null>(null)

    const [slots, setSlots] = useState<{ [day: string]: [SlotModel] }>({})
    const [slot, setSlot] = useState<SlotModel | null>(null)
    const [employees, setEmployees] = useState<{id:string, name: string, surname: string}[]>([])
    const [employee, setEmployee] = useState<string | null>(null)

    useEffect(() => {
        if(id !== undefined) {
            fetchBooking().then(() => {})
        }
    }, [id]);
    async function fetchBooking(): Promise<void> {
        try {
            const response = await fetch(`${APIurl}/getBookingBooking/${id}`, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                }
            })
            if (!response.ok) {
                setTab(TABS.NOTFOUND)
                const error = await response.clone().json();
                setError(error);
            } else {
                const data = await response.json()
                setBooking(data)
            }
        } catch (error) {
            setTab(TABS.NOTFOUND)
            throw error;
        }
    }

    if(loading) return <CircularProgress />
    return (
        <PageStandard headerVisible >
            <Stack direction="column" spacing={2} justifyContent="center" alignItems="center" pb={4} sx={{height: "70vh"}}>
                <SwitchTabs />
            </Stack>
        </PageStandard>
    )

    function SwitchTabs() {
        switch (tab) {
            case TABS.BOOKING:
                return <BookingTab />
            case TABS.DELETEDBOOKING:
                return <DeletedBookingTab />
            case TABS.EDITBOOKING:
                return <EditBookingTab />
            case TABS.OVERVIEWEDITBOOKING:
                return <OverviewTab />
            case TABS.CONFIRMEDITBOOKING:
                return <ConfirmEditBookingTab />
            case TABS.NOTFOUND:
                return <BookingNotFound />
        }
    }

    function BookingTab() {
        return (
            <Stack direction="column" spacing={2} justifyContent="center" alignItems="center">
                <Stack direction="column" spacing={2}>
                    <Card
                        size="lg"
                        variant="soft"
                        sx={{
                            borderRadius: 'xl',
                            minWidth: { xs:"auto", md:"350px" },
                        }}
                    >
                        <CardContent>
                            <Stack spacing={2}>
                                <Stack spacing={1}>
                                    <Typography level="h3">La tua prenotazione</Typography>
                                    <Stack>
                                        <Typography level="body-md">
                                            Data: <b>{moment(booking?.booking.start).format("DD/MM/YYYY")}</b>
                                        </Typography>
                                        <Typography level="body-md">
                                            Ora: <b>{moment(booking?.booking!.start).format("HH:mm")} - {moment(booking?.booking!.end).format("HH:mm")}</b>
                                        </Typography>
                                        <Typography level="body-md">
                                            Addetto: <b>{booking?.employeeName}</b>
                                        </Typography>
                                    </Stack>
                                </Stack>
                                <Stack spacing={1}>
                                    <Typography level="h3">I tuoi dati</Typography>
                                    <Stack>
                                        <Typography level="body-md">Nome: <b>{booking?.customer.name}</b></Typography>
                                        <Typography level="body-md">Cognome: <b>{booking?.customer.surname}</b></Typography>
                                        <Typography level="body-md">Email: <b>{booking?.customer.email}</b></Typography>
                                        <Typography level="body-md">Telefono: <b>{booking?.customer.phoneNumber}</b></Typography>
                                    </Stack>
                                </Stack>
                            </Stack>
                            {
                                (booking?.canEdit || booking?.canDelete) &&
                                <Stack direction="row" spacing={2} sx={{
                                    width: "100%",
                                    display: 'flex',
                                    justifyContent: "center",
                                    alignItems: "center",
                                    mt:2
                                }}>
                                    {
                                        booking?.canDelete &&
                                        <Button
                                            variant="outlined"
                                            color="danger"
                                            fullWidth
                                            onClick={() => {
                                                if(window.confirm("Eliminare definitivamente la prenotazione?")) {
                                                    deleteBooking().then(() => {})
                                                }
                                            }}
                                        >
                                            Annulla prenotazione
                                        </Button>
                                    }
                                    {
                                        booking?.canEdit &&
                                        <Button
                                            variant="solid"
                                            color="primary"
                                            fullWidth
                                            onClick={() => {
                                                setTab(TABS.EDITBOOKING)
                                                fetchSlots().then(() => {})
                                                fetchEmployees().then(() => {})
                                            }}
                                        >
                                            Modifica data/ora
                                        </Button>
                                    }
                                </Stack>
                            }
                        </CardContent>
                    </Card>
                </Stack>
            </Stack>
        )
    }
    async function deleteBooking() {
        setLoading(true)
        try {

            const response = await fetch(`${APIurl}/deleteBookingBooking/${id}`, {
                method: "DELETE",
                headers: {
                    "Content-Type": "application/json",
                },
            })
            if (!response.ok) {
                const error = await response.clone().json();
                setError(error);
            } else {
                setMessage("Prenotazione eliminata con successo")
                setTab(TABS.DELETEDBOOKING)
            }
        } catch (e: unknown) {
            setError(e)
        } finally {
            setLoading(false)
        }
    }
    async function fetchSlots(): Promise<void> {
        try {
            const response = await fetch(`${APIurl}/getBookingSlots/${booking?.booking.serviceId}/${null}`, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                }
            })
            if (!response.ok) {
                const error = await response.clone().json();
                setError(error);
            } else {
                const data = await response.json()
                if(data.code === 200) setSlots(data.slots)
                else {
                    setError(data.message)
                }
            }
        } catch (error) {
            throw error;
        }
    }
    async function fetchEmployees(): Promise<void> {
        try {
            const response = await fetch(`${APIurl}/getBookingEmployees`, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                }
            })
            if (!response.ok) {
                const error = await response.clone().json();
                setError(error);
            } else {
                const data = await response.json()
                setEmployees(data)
            }
        } catch (error) {
            throw error;
        }
    }

    function DeletedBookingTab() {
        return (
            <Stack direction="column" spacing={2} justifyContent="center" alignItems="center">
                <Typography level="h3">Prenotazione annullata</Typography>
                <Stack direction="row" spacing={2}>
                    <Link to="/prenota">
                        <Button>
                            Prenota un nuovo appuntamento
                        </Button>
                    </Link>
                </Stack>
            </Stack>
        )
    }

    function EditBookingTab() {
        const [modal, setModal] = useState(false)

        if(areObjectsEqual(slots, {})) return <CircularProgress />
        return (
            <Stack direction="column" spacing={2} justifyContent="center" alignItems="center">
                <Typography level="h3">Seleziona un orario</Typography>
                <Stack direction="row" spacing={2}>
                    {
                        Object.keys(slots).map(day => <Stack direction="column" spacing={2} key={day}>
                            <Typography level="body-lg" fontWeight="bold" textAlign="center">
                                {moment(day, "YYYY-MM-DD").format("DD/MM")}
                            </Typography>
                            <Stack direction="column" spacing={1} sx={{maxHeight: {xs:"60vh", md:"50vh"}, pl: 1}}
                                   className="scrollbarOrari">
                                {
                                    slots[day].map(s =>
                                        <Button variant={slot?.start === s.start ? "solid" : "outlined"}
                                                size="sm"
                                                key={s.id}
                                                onClick={() => {
                                                    if(s.employeeId.length === 1) {
                                                        setSlot(s)
                                                        setEmployee(s.employeeId[0])
                                                        setTab(TABS.OVERVIEWEDITBOOKING)
                                                    }
                                                    else {
                                                        setSlot(s)
                                                        setModal(true)
                                                    }
                                                }}
                                        >
                                            {moment(s.start, "YYYY-MM-DD HH:mm:ss").format("HH:mm")}
                                        </Button>
                                    )
                                }
                            </Stack>
                        </Stack>)
                    }
                </Stack>
                <Modal
                    open={modal}
                    onClose={() => setModal(false)}
                    sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                >
                    <ModalDialog>
                        <DialogTitle>Scegli un addetto</DialogTitle>
                        <DialogContent>Sono disponibili più persone che possomno eseguire questo servizio</DialogContent>
                        <Stack spacing={2}>
                            <Card onClick={() => {
                                setEmployee(slot!.employeeId[Math.floor(Math.random() * slot!.employeeId.length)])
                                setModal(false)
                                setTab(TABS.OVERVIEWEDITBOOKING)
                            }} className="hoverScale">
                                <Typography>Indifferente</Typography>
                            </Card>
                            {
                                slot?.employeeId.map(emp => {
                                    const e = employees.filter(a => a.id===emp)[0]
                                    return <Card key={emp} onClick={() => {
                                        setEmployee(emp)
                                        setModal(false)
                                        setTab(TABS.OVERVIEWEDITBOOKING)
                                    }} className="hoverScale">
                                        <Typography>{e.name} {e.surname}</Typography>
                                    </Card>
                                })
                            }
                        </Stack>
                    </ModalDialog>
                </Modal>
            </Stack>
        )
    }


    function OverviewTab() {

        const emp = employees.filter(a => a.id===employee)[0]
        return (
            <Stack direction="column" spacing={2} justifyContent="center" alignItems="center">
                <Typography level="h3">Riepilogo</Typography>
                <Stack direction="column" spacing={2}>
                    <Card
                        size="lg"
                        variant="soft"
                        sx={{
                            borderRadius: 'xl',
                            minWidth: { xs:"auto", md:"350px" },
                        }}
                    >
                        <CardContent>
                            <Stack spacing={2}>
                                <Stack spacing={1}>
                                    <Typography level="h3">La tua prenotazione</Typography>
                                    <Stack>
                                        <Typography level="body-md">
                                            Data: <b>{moment(slot!.start, "YYYY-MM-DD HH:mm:ss").format("DD/MM/YYYY")}</b>
                                        </Typography>
                                        <Typography level="body-md">
                                            Ora: <b>{moment(slot!.start, "YYYY-MM-DD HH:mm:ss").format("HH:mm")} - {moment(slot!.end, "YYYY-MM-DD HH:mm:ss").format("HH:mm")}</b>
                                        </Typography>
                                        <Typography level="body-md">
                                            Addetto: <b>{emp.name} {emp.surname}</b>
                                        </Typography>
                                    </Stack>
                                </Stack>
                                <Stack spacing={1}>
                                    <Typography level="h3">I tuoi dati</Typography>
                                    <Stack>
                                        <Typography level="body-md">Nome: <b>{booking?.customer.name}</b></Typography>
                                        <Typography level="body-md">Cognome: <b>{booking?.customer.surname}</b></Typography>
                                        <Typography level="body-md">Email: <b>{booking?.customer.email}</b></Typography>
                                        <Typography level="body-md">Telefono: <b>{booking?.customer.phoneNumber}</b></Typography>
                                    </Stack>
                                </Stack>
                            </Stack>
                        </CardContent>
                    </Card>
                    <Stack direction="row" spacing={2} sx={{
                        width: "100%",
                        display: 'flex',
                        justifyContent: "center",
                        alignItems: "center"
                    }}>
                        <Button
                            variant="outlined"
                            color="neutral"
                            fullWidth
                            onClick={() => {
                                setTab(TABS.EDITBOOKING)
                            }}
                        >
                            Indietro
                        </Button>
                        <Button
                            variant="solid"
                            color="primary"
                            fullWidth
                            disabled={loading}
                            onClick={() => {
                                updateBooking().then(() => {})
                            }}
                        >
                            Modifica prenotazione
                        </Button>
                    </Stack>
                </Stack>
            </Stack>
        )
    }
    async function updateBooking() {
        setLoading(true)
        try {
            const response = await fetch(`${APIurl}/updateBookingBooking/${id}`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    date: moment(slot!.start, "YYYY-MM-DD HH:mm:ss").format("YYYY-MM-DD"),
                    start: moment(slot!.start, "YYYY-MM-DD HH:mm:ss").format("HH:mm"),
                    serviceId: booking?.booking.serviceId,
                    employeeId: employee,
                })
            })
            if (!response.ok) {
                const error = await response.clone().json();
                setError(error);
            } else {
                setTab(TABS.CONFIRMEDITBOOKING)
            }
        } catch (e: unknown) {
            setError(e)
        } finally {
            setLoading(false)
        }
    }

    function ConfirmEditBookingTab() {
        return (
            <Stack direction="column" spacing={2} justifyContent="center" alignItems="center">
                <Typography level="h3">Prenotazione modificata</Typography>
                <Stack direction="row" spacing={2}>
                    <Button onClick={() => window.location.reload()}>
                        Gestisci la prenotazione
                    </Button>
                </Stack>
            </Stack>
        )
    }

    function BookingNotFound() {
        return (
            <Stack direction="column" spacing={2} justifyContent="center" alignItems="center">
                <Typography level="h3">Prenotazione non trovata</Typography>
                <Stack direction="row" spacing={2}>
                    <Link to="/gestisci-prenotazione">
                        <Button>
                            Cerca di nuovo
                        </Button>
                    </Link>
                </Stack>
            </Stack>
        )
    }
}