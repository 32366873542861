import {PageStandard} from "../utils/PageStandard";
import {ReactNode, useContext, useEffect, useState} from "react";
import {
    AspectRatio, Button,
    Card, CardContent, CardOverflow, Checkbox, Chip,
    CircularProgress, DialogContent, DialogTitle, Divider, FormControl, FormHelperText, FormLabel,
    Grid, Input, Modal, ModalDialog, Option, Select, Sheet,
    Stack,
    Step,
    stepClasses,
    StepIndicator,
    stepIndicatorClasses,
    Stepper, Switch,
    Typography
} from "@mui/joy";
import {CheckCircle, ConfirmationNumber, ContentCut, Info, Event, Archive, Inventory} from "@mui/icons-material";
import {FeedbackContext} from "../utils/FeedbackContext";
import {BookingModel, ServiceModel, SlotModel, UserModel} from "../utils/Model";
import {
    APIurl,
    areObjectsEqual, clearPhone,
    formatPriceEuro, genUUID, getFlagEmoji,
    isValidEmail, isValidPhoneNumber,
    minutesToHoursString,
    TelefonoInput
} from "../utils/Utils";
import moment from "moment";
import {Link, useParams} from "react-router-dom";


export function GestisciPrenotazione() {
    const {loading, bigloading, error, message, setLoading, setBigLoading, setError, setMessage, company, logo} = useContext(FeedbackContext)
    const [code, setCode] = useState("");

    return (
        <PageStandard headerVisible >
            <Stack direction="column" spacing={2} justifyContent="center" alignItems="center" pb={4} sx={{height: "70vh"}}>
                <Stack spacing={3} direction="column">
                    <FormControl>
                        <FormLabel>Codice prenotazione</FormLabel>
                        <Input type="text" placeholder="XXXXXXXXXX"
                               value={code}
                               onChange={e => setCode(e.target.value)}
                               slotProps={{
                                   input: {
                                       maxLength: 10
                                   },
                               }}
                               className="span-as-pre"
                        />
                        <FormHelperText>
                            Il codice contiene 10 caratteri alfanumerici
                        </FormHelperText>
                    </FormControl>
                    <Stack direction="row" spacing={2} sx={{ display: 'flex', justifyContent:"center", alignItems:"center"}}>
                        <Link to="/">
                            <Button
                                variant="outlined"
                                color="neutral"
                                fullWidth
                            >
                                Indietro
                            </Button>
                        </Link>
                        <Button
                            variant="solid"
                            fullWidth
                            disabled={code.length !== 10}
                            onClick={() => {
                                window.location.href = "/b/" + code
                            }}
                        >
                            Cerca prenotazione
                        </Button>
                    </Stack>

                </Stack>
            </Stack>
        </PageStandard>
    )
}