import {Box, Container, GlobalStyles, IconButton, Sheet, Stack, Typography} from "@mui/joy";
import {LogoOctopus} from "../assets/Logo";
import {Link} from "react-router-dom";
import {useContext} from "react";
import {FeedbackContext} from "./FeedbackContext";


export function Header() {
    const {company, logo} = useContext(FeedbackContext)

    const companyName = company?.shortName ?? "Octopus Desk"
    document.title = companyName

    return (
        <Sheet
            sx={{
                display: { xs: 'flex', md: 'flex' },
                alignItems: 'center',
                justifyContent: 'space-between',
                position: 'fixed',
                top: 0,
                width: '100vw',
                height: "4em",
                zIndex: 9995,
                p: 2,
                gap: 1,
                borderBottom: '1px solid',
                borderColor: 'background.level1',
                boxShadow: 'sm',
            }}
        >
            <GlobalStyles
                styles={(theme) => ({
                    ':root': {
                        '--Header-height': '52px',
                        [theme.breakpoints.up('md')]: {
                            '--Header-height': '0px',
                        },
                    },
                })}
            />
            <Container>
                <Stack direction="row" spacing={2} my={2} justifyContent="space-between" alignItems="center">
                    <Box sx={{ display: 'flex', gap: 1, justifyContent:"center", alignItems: 'center' }}>
                        {
                            logo ?
                                <img src={logo}
                                     alt={companyName}
                                     height={40}
                                     style={{aspectRatio:1}}
                                />
                                :
                                <IconButton variant="soft" color="primary" size="sm">
                                    <LogoOctopus width="20" height="20" color="var(--joy-palette-primary-500)" />
                                </IconButton>
                        }
                        <Typography level="title-lg">{companyName}</Typography>
                    </Box>
                    <Stack direction="row" spacing={2} alignItems="center">
                        <Link to="/prenota" className="linkMenu">
                            <Typography level="title-md">Prenota</Typography>
                        </Link>
                        <Link to="/gestisci-prenotazione" className="linkMenu">
                            <Typography level="title-md">Gestisci prenotazione</Typography>
                        </Link>
                    </Stack>
                </Stack>
            </Container>
        </Sheet>
    );
}