import {PageStandard} from "../utils/PageStandard";
import {AspectRatio, Box, Card, IconButton, Stack, Typography} from "@mui/joy";
import {Link} from "react-router-dom";
import {ArrowForward, ContentCut, EditCalendar, Event} from "@mui/icons-material";
import {useContext} from "react";
import {FeedbackContext} from "../utils/FeedbackContext";


export function Home() {

    const {company, logo} = useContext(FeedbackContext)

    return (
        <PageStandard headerVisible={true}>
            <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" sx={{height: '70vh', width:"100%"}}>
                <Stack direction="row" justifyContent="center" alignItems="center" spacing={4} sx={{height: '100%', width:"100%"}}>
                    <Link to="/prenota">
                        <Card
                            size="lg"
                            variant="soft"
                            color="success"
                            invertedColors
                            sx={{ maxWidth: 300, boxShadow: 'lg', borderRadius: 'xl'}}
                            className="hoverScale"
                        >
                            <AspectRatio
                                data-skip-inverted-colors
                                variant="soft"
                                color="neutral"
                                ratio="1"
                                sx={{ width: 48 }}
                            >
                                <div>
                                    <Event />
                                </div>
                            </AspectRatio>

                            <Typography level="h3">Prenota</Typography>
                            <Typography level="body-sm">
                                Prenota adesso il tuo taglio. Scegli il tipo di servizio, il tuo barbiere di fiducia e la data che preferisci.
                            </Typography>

                            <IconButton
                                variant="plain"
                                size="lg"
                                sx={{ alignSelf: 'flex-end', borderRadius: '50%', mr: -1, my: -1 }}
                            >
                                <ArrowForward />
                            </IconButton>
                        </Card>
                    </Link>
                    <Link to="/gestisci-prenotazione">
                        <Card
                            size="lg"
                            variant="soft"
                            color="danger"
                            invertedColors
                            sx={{ maxWidth: 300, boxShadow: 'lg', borderRadius: 'xl'}}
                            className="hoverScale"
                        >
                            <AspectRatio
                                data-skip-inverted-colors
                                variant="soft"
                                color="neutral"
                                ratio="1"
                                sx={{ width: 48 }}
                            >
                                <div>
                                    <EditCalendar />
                                </div>
                            </AspectRatio>

                            <Typography level="h3">Gestisci prenotazione</Typography>
                            <Typography level="body-sm">
                                Nessun problema! Puoi scegliere un giorno o un orario diverso, oppure puoi annullare la tua prenotazione.
                            </Typography>

                            <IconButton
                                variant="plain"
                                size="lg"
                                sx={{ alignSelf: 'flex-end', borderRadius: '50%', mr: -1, my: -1 }}
                            >
                                <ArrowForward />
                            </IconButton>
                        </Card>
                    </Link>

                </Stack>
            </Box>
        </PageStandard>
    )
}