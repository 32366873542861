import {BrowserRouter, Route, Routes} from "react-router-dom";
import {useContext, useEffect, useState} from "react";
import {Page404} from "../pages/Page404";
import {ServerSettingsModel} from "./Model";
import {APIurl} from "./Utils";
import {FeedbackContext} from "./FeedbackContext";
import {MainteinancePage} from "../pages/MainteinancePage";
import {Home} from "../pages/Home";
import {Prenota} from "../pages/Prenota";
import {Prenotazione} from "../pages/Prenotazione";
import {GestisciPrenotazione} from "../pages/GestisciPrenotazione";
import {SubscriptionInactive} from "../pages/SubscriptionInactive";

export function Router() {

    const {bigLoading, setBigLoading, setError, company} = useContext(FeedbackContext)

    const [serverSettings, setServerSettings] = useState<ServerSettingsModel | undefined>()
    useEffect(() => {
        fetchServerSettings().then(() => {})
    }, []);
    async function fetchServerSettings(): Promise<void> {
        setBigLoading(true)
        try {
            const response = await fetch(`${APIurl}/getServerSettings`, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json"
                }
            })
            if (!response.ok) {
                const error = await response.clone().json();
                setError(error);
            } else {
                const data = await response.json()
                setServerSettings(data)
            }
        } catch (error) {
            throw error;
        } finally {
            setBigLoading(false)
        }
    }

    return (
        <>
            {
                serverSettings?.mainteinance ?
                    <MainteinancePage />
                    :
                company && !company?.subscriptionActive ?
                    <SubscriptionInactive />
                    :
                    <BrowserRouter>
                        <Routes>
                            <Route path="/" element={<Home />}/>
                            <Route path="/gestisci-prenotazione" element={<GestisciPrenotazione />}/>
                            <Route path="/prenota" element={<Prenota />}/>
                            <Route path="/b/:id" element={<Prenotazione />}/>
                            <Route path="*" element={<Page404 />}/>
                        </Routes>
                    </BrowserRouter>
            }
        </>
    )
}