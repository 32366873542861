import {Box, Stack, Typography} from "@mui/joy";

export function Page404() {


    return (
        <Box sx={{height:"100vh", textAlign:"center", display:"flex", justifyContent:"center", alignItems:"center"}}>
            <Stack spacing={1} sx={{pb:5}}>
                <Typography level="h1" sx={{fontSize:"4rem"}}>
                    404
                </Typography>
                <Typography level="body-lg">
                    Pagina non trovata
                </Typography>
            </Stack>
        </Box>
    )
}