import {Box, Container, GlobalStyles, IconButton, Sheet, Stack, Typography} from "@mui/joy";
import {LogoOctopus} from "../assets/Logo";
import {Link} from "react-router-dom";
import {useContext} from "react";
import {FeedbackContext} from "./FeedbackContext";


export function Footer() {
    const {company, logo} = useContext(FeedbackContext)


    return (
        <Sheet
            sx={{
                display: { xs: 'flex', md: 'flex' },
                alignItems: 'center',
                justifyContent: 'space-between',
                width: '100%',
                minHeight: "10em",
                p: 2,
                gap: 1,
                borderTop: '1px solid',
                borderColor: 'background.level1',
                boxShadow: 'md',
            }}
        >
            <Container>
                <Stack direction="row" spacing={2} my={2} justifyContent="space-between" alignItems="end" sx={{filter:"grayscale(100%)", opacity:0.75}}>
                    <Stack direction="column" textAlign="left">
                        {
                            logo ?
                                <img src={logo}
                                     alt={company?.shortName}
                                     width={40}
                                     height={40}
                                     style={{aspectRatio:1, marginBottom:"1rem"}}
                                />
                                :
                                null
                        }
                        <Typography level="body-sm" fontWeight="bold">{company?.longName}</Typography>
                        <Typography level="body-xs">{company?.address}</Typography>
                        <Typography level="body-xs">{company?.postalCode}, {company?.city} ({company?.state}) - {company?.country}</Typography>
                        <Typography level="body-xs" mt={1}>VAT: {company?.vatNumber}</Typography>
                    </Stack>
                    <Stack direction="column" textAlign="right">
                        <Typography level="body-xs">Sistema di prenotazioni online gestito da <a href="https://www.octopusdesk.it" rel="noreferrer" target="_blank"><u>Octopus Desk</u></a></Typography>
                    </Stack>
                </Stack>
            </Container>
        </Sheet>
    );
}