import {AspectRatio, Box, Button, Sheet, Stack, Typography} from "@mui/joy";
import {LogoOctopus} from "../assets/Logo";
import React from "react";


export function SubscriptionInactive() {
    return (
        <Box sx={{width:"100%", height:"100vh", display:"flex", justifyContent:"center", alignItems:"center", textAlign:"center"}}>
            <Stack spacing={5}>
                <Box sx={{ display: 'flex', gap: 2, flexDirection:"row", justifyContent:"center", alignItems: 'center' }}>
                    <AspectRatio ratio={1} sx={{width:60, borderRadius:20}}>
                        <Sheet variant="soft" color="primary" >
                            <LogoOctopus width="40" height="40" color="var(--joy-palette-primary-500)" />
                        </Sheet>
                    </AspectRatio>
                    <Typography level="h3">Octopus Desk</Typography>
                </Box>
                <Stack spacing={1}>
                    <Typography level="h1">
                        Non è possibile raggiungere questo sito
                    </Typography>
                    <Typography level="body-md">
                        Ci scusiamo per il disagio.
                    </Typography>
                </Stack>
            </Stack>
        </Box>
    )
}