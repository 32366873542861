import {Header} from "./Header";
import {Box, Container} from "@mui/joy";
import {ReactNode} from "react";
import {Footer} from "./Footer";


export function PageStandard(props: {headerVisible: boolean, children: ReactNode}) {

    return (
        <Box sx={{ minHeight: '100dvh' }}>
            {
                props.headerVisible ?
                    <>
                        <Header />
                    </>
                    :
                    null
            }
            <Container sx={{
                pt: "7em",
                flex: 1,
                display: 'flex',
                flexDirection: 'column',
                minWidth: 0,
                minHeight: '80dvh',
                gap: 1,
            }}>
                {props.children}
            </Container>
            <>
                <Footer />
            </>
        </Box>
    )
}